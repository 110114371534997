import React from "react";

export default function PlusIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_503_11891"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          d="M2.75 21.25V2.75H21.25V21.25H2.75Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
      </mask>
      <g mask="url(#mask0_503_11891)">
        <path
          d="M12.0088 2.83398V21.1673"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1668 12.0098H2.8335"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
