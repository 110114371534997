import Image from "next/image";
import Link from "next/link";
import MenuLink from "./MenuLink";
import Container from "./Container2";
import MenuIcon from "./icons/MenuIcon";
import CloseIcon from "./icons/CloseIcon";
import { primaryMenu } from "../lib/primary-menu";
import { AnimatePresence } from "framer-motion";
import SearchIcon from "./icons/SearchIcon";

interface Props {
  handleSearchSwitch: () => void;
  setMobileMenu?: (value: boolean) => void;
  isMobileMenu?: boolean;
  style?: string;
}

export default function Header({
  style = null,
  isMobileMenu,
  ...props
}: Props) {
  function switchMobileMenu() {
    if (isMobileMenu) {
      props.setMobileMenu(false);
      return;
    }

    props.setMobileMenu(true);
    return;
  }

  return (
    <>
      <div
        className={`header fixed top-0 left-0 w-full z-30 transition ${
          style != "dark" ? "bg-[#fff] text-[#121212]" : "text-white"
        }`}
      >
        <Container>
          <div className="py-6 flex items-center h-24 gap-6 lg:gap-16 justify-between">
            <div className="flex flex-row gap-6 lg:gap-16 items-center">
              <div
                className="lg:hidden w-6 h-6 cursor-pointer"
                onClick={switchMobileMenu}
              >
                <AnimatePresence>
                  {isMobileMenu ? (
                    <CloseIcon
                      className={`${
                        style != "dark" ? "text-[#121212]" : "text-white"
                      }`}
                    />
                  ) : (
                    <MenuIcon
                      className={`${
                        style != "dark" ? "text-[#121212]" : "text-white"
                      }`}
                    />
                  )}
                </AnimatePresence>
              </div>

              <div className="naming">
                <div className="text-xl md:text-2xl font-bold tracking-tight md:tracking-tighter leading-tight">
                  <Link href="/" className="hover:underline" prefetch={false}>
                    <Image
                      className={`w-8 md:w-10 lg:w-12 ${
                        style != "dark" || isMobileMenu ? "" : "invert"
                      }`}
                      src="/images/logo-numa-48.png"
                      loading={"eager"}
                      priority={true}
                      width={48}
                      height={48}
                      alt={"Numa Logotype"}
                    />
                  </Link>
                </div>
              </div>

              <div className="hidden lg:block">
                <ul className="flex flex-row gap-12 font-medium">
                  <li>
                    <MenuLink uri="/">All</MenuLink>
                  </li>
                  <li>
                    <MenuLink uri="/cat/architecture">Architecture</MenuLink>
                  </li>
                  <li>
                    <MenuLink uri="/cat/3d-visualization">
                      3D Visualization
                    </MenuLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-row gap-16 items-center">
              <div className="ml-auto hidden lg:block">
                <ul className="flex flex-row gap-12 font-medium">
                  {primaryMenu.map((item, i) => {
                    return (
                      <li key={i}>
                        <MenuLink uri={item.url}>{item.title}</MenuLink>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="ml-auto lg:ml-0 flex">
                <button
                  onClick={props.handleSearchSwitch}
                  className="hover:opacity-50 transition-all"
                >
                  <SearchIcon
                    className={
                      style != "dark" || isMobileMenu ? "" : "text-white"
                    }
                  />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
