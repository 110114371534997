import React, { useState } from "react";
import ShareIcon from "./icons/ShareIcon";
import Link from "next/link";
import CloseIcon from "./icons/CloseIcon";
import { motion, AnimatePresence } from "framer-motion";

export default function Share({ className = "transition hover:opacity-70" }) {
  const [isOpen, setOpen] = useState(false);
  let currentUrl;

  if (typeof window !== "undefined") {
    currentUrl = window.location.href;
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="pointer-events-none z-40 w-full h-full fixed top-0 left-0 flex justify-center items-center" key="1">
          <motion.div
            className="bg-[#121212] bg-opacity-50 w-full h-full absolute pointer-events-auto"
            onClick={() => {
              setOpen(false);
            }}
            initial={{ opacity: 0 }}
            animate={{opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div className="text-[#121212] bg-white p-8 z-50 pointer-events-auto shadow-2xl" 
            initial={{ y: "75vh", opacity: 0 }}
            animate={{ y: "0vh", opacity: 1 }}
            exit={{ y: "75vh", opacity: 0 }}
            >
            <div className="flex justify-between">
              <h3 className="font-bold lowercase text-lg">Share</h3>
              <button onClick={()=>{setOpen(false)}}>
                <CloseIcon className="w-4 h-4" />
              </button>
            </div>
            <div className="flex gap-4 mt-6">
              <Link
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl
                }
                target="_blank"
                className="hover:opacity-50 transition-all"
              >
                <img src="/images/facebook2.svg" className="w-6 h-6" />
              </Link>
              <Link
                href={
                  "https://www.linkedin.com/shareArticle?mini=true&url=" +
                  currentUrl
                }
                target="_blank"
                className="hover:opacity-50 transition-all"
              >
                <img src="/images/linkedin.svg" className="w-6 h-6" />
              </Link>
              <Link
                href={
                  "https://pinterest.com/pin/create/button/?url=" + currentUrl
                }
                target="_blank"
                className="hover:opacity-50 transition-all"
              >
                <img src="/images/pinterest2.svg" className="w-6 h-6" />
              </Link>
              <Link
                href={"https://api.whatsapp.com/send?text=" + currentUrl}
                target="_blank"
                className="hover:opacity-50 transition-all"
              >
                <img src="/images/whatsapp.svg" className="w-6 h-6" />
              </Link>
              <Link
                href={"https://twitter.com/intent/tweet?url=" + currentUrl}
                target="_blank"
                className="hover:opacity-50 transition-all"
              >
                <img src="/images/twitter2.svg" className="w-6 h-6" />
              </Link>
            </div>
          </motion.div>
        </div>
      )}
      <button
        className={className}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ShareIcon />
      </button>
    </AnimatePresence>
  );
}
