import React from "react";
import MenuLink from "./MenuLink";
import Link from "next/link";
import Image from "next/image";
import { primaryMenu } from "../lib/primary-menu";
import { AnimatePresence, motion } from "framer-motion";
import CloseIcon from "./icons/CloseIcon";

function SocialLink({ link, image, alt }) {
  return (
    <Link href={link} target="_blank">
      <Image src={image} width={24} height={24} alt={alt} />
    </Link>
  );
}

export default function MobileMenu({ ...props }) {
  function switchMobileMenu() {
    if (props.isMobileMenu) {
      props.setMobileMenu(false);
      return;
    }

    props.setMobileMenu(true);
    return;
  }

  return (
    <AnimatePresence>
      {props.isMobileMenu && (
        <>
          <div
            className="fixed w-full h-full top-0 lg:hidden z-30"
            onClick={() => props.setMobileMenu(false)}
          />
          <motion.div className="fixed top-0 left-0 w-full h-full z-30 pointer-events-none lg:hidden">
            <motion.div
              className="min-h-full w-[50%] min-w-[200px] z-20 bg-white text-[#121212] p-8 flex flex-col gap-12 justify-between shadow-md pointer-events-auto h-full overflow-y-auto"
              initial={{ x: "-100%", y: 0 }}
              animate={{ x: 0, y: 0 }}
              exit={{ x: "-100%", y: 0 }}
              transition={{ duration: 0.25, type: "easeIn" }}
              key="432"
            >
              <div className="flex flex-row gap-6 items-center">
                <div
                  className="lg:hidden w-6 h-6 cursor-pointer"
                  onClick={switchMobileMenu}
                >
                  <AnimatePresence>
                    <CloseIcon className="text-[#121212]" />
                  </AnimatePresence>
                </div>

                <div className="naming">
                  <div className="text-xl md:text-2xl font-bold tracking-tight md:tracking-tighter leading-tight">
                    <Link href="/" className="hover:underline">
                      <Image
                        className={`w-8 md:w-10 lg:w-12 `}
                        src="/images/logo-numa-48.png"
                        loading={"eager"}
                        priority={true}
                        width={48}
                        height={48}
                        alt={"Numa Logotype"}
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div>
                <ul className="flex flex-col gap-6 font-medium">
                  <li>
                    <MenuLink uri="/">All</MenuLink>
                  </li>
                  <li>
                    <MenuLink uri="/cat/architecture">Architecture</MenuLink>
                  </li>
                  <li>
                    <MenuLink uri="/cat/3d-visualization">
                      3D Visualization
                    </MenuLink>
                  </li>
                </ul>
              </div>
              <div className="mt-auto">
                <ul className="flex flex-col gap-6 font-medium">
                  {primaryMenu.map((item, i) => {
                    return (
                      <li key={i}>
                        <MenuLink uri={item.url}>{item.title}</MenuLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <ul className="flex gap-4">
                  <li>
                    <SocialLink
                      link="https://www.facebook.com/nu.ma.arq/"
                      image="/images/facebook2.svg"
                      alt="Facebook"
                    />
                  </li>
                  <li>
                    <SocialLink
                      link="https://www.instagram.com/numa_arq/"
                      image="/images/instagram5.svg"
                      alt="Instagram"
                    />
                  </li>
                  <li>
                    <SocialLink
                      link="https://www.pinterest.pt/numa_arq/"
                      image="/images/pinterest2.svg"
                      alt="Pinterest"
                    />
                  </li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
