import Image from "next/image";
import React, { useState } from "react";
import { motion } from "framer-motion";

type Props = {
  projectData: any;
  projectThumbnail: any;
  onClickCallback: any;
  visible: boolean;
};

export default function HomeImage({
  projectThumbnail,
  onClickCallback,
  visible,
  projectData,
} : Props) {
  let className = "relative bg-neutral-200 align-bottom";
  const [isReady, setReady] = useState(false);
  const [isHover, setHover] = useState(false);
  const ytUrl = `https://www.youtube-nocookie.com/embed/${projectData.fields?.video}?autoplay=1&mute=1&modestbranding=1&showinfo=0&autohide=1&rel=0&controls=0`;
  const imgLoadingClassname = isReady ? "opacity-100" : "opacity-0";
  const handleProjectClick = () => onClickCallback(projectData);

  if (!visible) className += " opacity-0 hidden";

  if (projectThumbnail)
    return (
      <div
        className={className}
        onClick={handleProjectClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Image
          quality={90}
          width={projectThumbnail.mediaDetails.width}
          height={projectThumbnail.mediaDetails.height}
          alt={projectData.title}
          src={projectThumbnail['large']}
          onLoadingComplete={() => setReady(true)}
          className={`transition-all duration-500 w-full h-full overflow-hidden object-cover object-left cursor-pointer ${imgLoadingClassname}`}
        />
        {projectData.fields?.video !== null &&
          isHover &&
          process.env.PROJECT_YT && (
            <motion.div
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-black absolute w-full h-full top-0 left-0 z-10"
            >
              <iframe src={ytUrl} className="w-full h-full z-10 transition" />
              <div className="absolute top-0 left-0 w-full h-full pointer-events-auto z-30 cursor-pointer"></div>
            </motion.div>
          )}
      </div>
    );

  return <></>;
}
