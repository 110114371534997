const API_URL = process.env.WORDPRESS_API_URL;
const PUB_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
let isServer = (process.browser) ? false : true;

export async function fetchAPI(query = "", { variables }: Record<string, any> = {}) {
  const headers = { "Content-Type": "application/json" };

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers[
      "Authorization"
    ] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`;
  }

  // WPGraphQL Plugin must be enabled
  // Minimal change on API_URL and PUB_API_URL to work server/client with the same function
  // No Need for 2nd client-side only function that does the same
  const res = await fetch(isServer ? API_URL : PUB_API_URL, {
    headers,
    method: "POST",
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  return json.data;
}

export async function getAllPagesPaths() {
  const data = await fetchAPI(
    `
    query AllPosts {
      pages(first: 100) {
        edges {
          node {
            slug
            pagesFields {
              template
            }
          }
        }
      }
    }
  `);
  return data?.pages ? data.pages : null;
}

export async function getAllPostsForHome() {
  const data = await fetchAPI(
    `
    query AllPosts {
      posts(first: 20, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                firstName
                lastName
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  `
  );

  return data?.posts ? data.posts : null;
}

export async function getPostMedia(parent = "", perPage = 30) {

  const data = await fetchAPI(
    `
    query Projects($parent: ID = "", $perPage: Int = 30) {
      mediaItems(where: {parent: $parent, orderby: {field: MENU_ORDER, order: ASC}} first: $perPage) {
        edges {
          node {
            date
            title
            fileSize
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  `,
    {
      variables: { parent, perPage: 30 },
    }
  );

  return data?.mediaItems ? data.mediaItems : null;
}

export async function getAllProjectsForHome(preview) {
  const data = await fetchAPI(
    `
    query AllProjects {
      projects {
        edges {
          node {
            projectId
            slug
            terms {
              edges {
                node {
                  ... on ProjectCategory {
                    slug
                    name
                  }
                }
              }
            }
            title
            featuredImage {
              node {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
            }
            fields {
              architecture
              architectureUrl
              architectureCoauthor
              location
              locationUrl
              date
              projectTeam
              client
              clientUrl
              construction
              constructionUrl
              photography
              photographyUrl
              photographyCoauthor
              field3d
              field3dUrl
              field3dCoauthor
              state
              video
              download {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
      },
    }
  );

  return data?.projects;
}

export async function getProjectBySearch(search = "", perPage = 30) {
  const data = await fetchAPI(
    `
    query SearchedProjects($search: String = "", $perPage: Int = 30) {
      projects(where: {search: $search} first: $perPage) {
        edges {
          node {
            projectId
            slug
            terms {
              edges {
                node {
                  ... on ProjectCategory {
                    slug
                    name
                  }
                }
              }
            }
            title
            featuredImage {
              node {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
                medium: sourceUrl(size: MEDIUM)
                large: sourceUrl(size: LARGE)
                original: sourceUrl
              }
            }
            fields {
              architecture
              architectureUrl
              architectureCoauthor
              location
              locationUrl
              date
              projectTeam
              client
              clientUrl
              construction
              constructionUrl
              photography
              photographyUrl
              photographyCoauthor
              field3d
              field3dUrl
              field3dCoauthor
              state
              video
              download {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: { search },
    }
  );

  return data?.projects;
}


export async function getProjectCategorySEO(id: String = "") {
  const data = await fetchAPI(`
  query getSeo($idType: ProjectCategoryIdType = SLUG, $id: ID = "") {
    projectCategory(idType: $idType, id: $id) {
      seo {
        metaDesc
        focuskw
        cornerstone
        canonical
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphTitle
        opengraphSiteName
        opengraphImage {
          mediaItemUrl
        }
        title
      }
      slug
    }
    }`,
    {
      variables: { idType: "SLUG", id },
    }
  )


  return data?.projectCategory ? data.projectCategory : null;
}


export async function getHomepageSEO() {
  const data = await fetchAPI(`
    query getHomepageSeo {
      seo {
        meta {
          homepage {
            title
            description
          }
        }
        openGraph {
          defaultImage {
            mediaItemUrl
          }
          frontPage {
            image {
              mediaItemUrl
            }
          }
        }
      }
    }`
  )


  return data ? data : null;
}

// If it has categorySlug so the request will be specific for a category
// If not the request changes to all projects
// The value of perPage will be the same
export async function getProjects(categorySlug = null, perPage = 30, lastPage = "") {
  let data;

  if (categorySlug) {
    data = await fetchAPI(
      `
      query Projects($perPage: Int = 10, $categorySlug: ID = "", $lastPage: String = "") {
        projectCategory(idType: SLUG, id: $categorySlug) {
          projects(first: $perPage, after: $lastPage) {
            edges {
              node {
                projectId
                slug
                terms {
                  edges {
                    node {
                      ... on ProjectCategory {
                        slug
                        name
                      }
                    }
                  }
                }
                title
                featuredImage {
                  node {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                    medium: sourceUrl(size: MEDIUM)
                    large: sourceUrl(size: LARGE)
                    original: sourceUrl
                  }
                }
                fields {
                  architecture
                  architectureUrl
                  architectureCoauthor
                  location
                  locationUrl
                  date
                  projectTeam
                  client
                  clientUrl
                  construction
                  constructionUrl
                  photography
                  photographyUrl
                  photographyCoauthor
                  field3d
                  field3dUrl
                  field3dCoauthor
                  state
                  video
                  download {
                    mediaItemUrl
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
          slug
          seo {
            metaDesc
            metaKeywords
            title
            opengraphSiteName
            opengraphTitle
            breadcrumbs {
              text
            }
            opengraphUrl
            opengraphType
            opengraphImage {
              mediaItemUrl
              link
              sourceUrl(size: MEDIUM)
            }
            metaRobotsNoindex
            metaRobotsNofollow
          }
        }
      }
    `,
      {
        variables: { categorySlug, perPage, lastPage },
      }
    );
  } else {
    data = await fetchAPI(
      `
      query Projects($perPage: Int = 10, $lastPage: String = "") {
        projects(first: $perPage, after: $lastPage) {
          edges {
            node {
              projectId
              slug
              terms {
                edges {
                  node {
                    ... on ProjectCategory {
                      slug
                      name
                    }
                  }
                }
              }
              title
              featuredImage {
                node {
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                  medium: sourceUrl(size: MEDIUM)
                  large: sourceUrl(size: LARGE)
                  original: sourceUrl
                }
              }
              fields {
                architecture
                architectureUrl
                architectureCoauthor
                location
                locationUrl
                date
                projectTeam
                client
                clientUrl
                construction
                constructionUrl
                photographyCoauthor
                photography
                photographyUrl
                field3d
                field3dUrl
                field3dCoauthor
                state
                video
                download {
                  mediaItemUrl
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    `, {
      variables: { perPage, lastPage },
    }
    );
  }

  return data?.projectCategory ? data.projectCategory : data;
}

export async function getProject(slug = null) {
  let data = await fetchAPI(
    `
    query Projects($slug: String = "") {
      projectBy(slug: $slug) {
        projectId
        slug
        terms {
          edges {
            node {
              ... on ProjectCategory {
                slug
                name
              }
            }
          }
        }
        title
        featuredImage {
          node {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
            medium: sourceUrl(size: MEDIUM)
            large: sourceUrl(size: LARGE)
            original: sourceUrl
          }
        }
        fields {
          architecture
          architectureUrl
          architectureCoauthor
          location
          locationUrl
          date
          projectTeam
          client
          clientUrl
          construction
          constructionUrl
          photography
          photographyUrl
          photographyCoauthor
          field3d
          field3dUrl
          field3dCoauthor
          state
          video
          download {
            mediaItemUrl
          }
        }
        seo {
          title
          opengraphType
          opengraphImage {
            mediaItemUrl
          }
          opengraphDescription
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          breadcrumbs {
            text
          }
        }
      }
    }
    `,
    {
      variables: { slug },
    }
  );


  return data?.projectBy ? data.projectBy : null;
}

export async function getPageBySlug(slug) {
  const data = await fetchAPI(
    `
    query GetPageBySlug($slug: String = null) {
      pageBy(uri: $slug) {
        title
        uri
        content
        slug
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        pagesFields {
          address
          addressLink
          template
          block1Description
          block2Description
          numaDescription
          numaImage {
            sourceUrl
          }
          block2Image {
            sourceUrl
          }
          block1Image {
            sourceUrl
          }
        }
        seo {
          metaDesc
          metaKeywords
          opengraphImage {
            sourceUrl(size: MEDIUM)
          }
          opengraphType
          opengraphTitle
        }
      }
    }
  `,
    {
      variables: { slug },
    }
  );

  return data?.pageBy ? data.pageBy : null;
}
