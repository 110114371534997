import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";

export default function MobileProjectContent({ isOpen, projectData }) {
  const itemClass = "flex flex-col items-center";
  const itemHeadingClass = "font-medium lowercase";
  const linkClass = "transition-all hover:opacity-70 underline underline-offset-4 decoration-neutral-700";

  function Item({ title, param, coauthor }: { title: string; param: string, coauthor?: boolean }) {
    const hasCoauthor = coauthor && projectData.fields[param + "Coauthor"];


    if (projectData.fields?.[param]) {
      const url = projectData.fields[param + "Url"];

      const FieldContent = () => {
        return <span>{projectData.fields[param]}</span>;
      };

      return (
        <div className={itemClass}>
          <h2 className={itemHeadingClass}>{title}</h2>
          {hasCoauthor ? (
            url ? (
              <div className="inline">
                <FieldContent />
                <span>{" + "}</span>
                <Link
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={linkClass}
                >
                  <span>{projectData.fields[param + "Coauthor"]}</span>
                </Link>
              </div>
            ) : (
              <>
                <FieldContent />
                <span>{" + "}</span>
                <span>{projectData.fields[param + "Coauthor"]}</span>
              </>
            )
          ) : url ? (
            <Link
              href={url}
              target="_blank"
              rel="noreferrer"
              className={linkClass}
            >
              <FieldContent />
            </Link>
          ) : (
            <FieldContent />
          )}
        </div>
      );
    }

    return <></>;
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={`font-regular text-base flex flex-col justify-center w-full gap-2 md:hidden`}
          key="1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "linear",
            ease: "easeIn",
          }}
        >
          <Item title="Architecture" param="architecture" coauthor={true} />
          <Item title="Location" param="location" />
          <Item title="Date" param="date" />
          <Item title="Project Team" param="projectTeam" />
          <Item title="Client" param="client" />
          <Item title="Construction" param="construction" />
          <Item title="Photography" param="photography" coauthor={true} />
          <Item title="State" param="state" />
          <Item title="3d" param="field3d" coauthor={true} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
