import React from "react";

export default function SearchIcon({ className = "" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Clip path group">
        <mask
          id="mask0_595_10325"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <g id="clip0_155_17">
            <path id="Vector" d="M20 0H0V20H20V0Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_595_10325)">
          <g id="Group">
            <path
              id="Vector_2"
              d="M8.43103 15.5C12.4811 15.5 15.7644 12.2168 15.7644 8.16661C15.7644 4.11651 12.4811 0.833252 8.43103 0.833252C4.38092 0.833252 1.09766 4.11651 1.09766 8.16661C1.09766 12.2168 4.38092 15.5 8.43103 15.5Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M13.3477 13.6152L18.8991 19.1666"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
