import React from 'react'

export default function TopArrow() {
  return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 19L13 8L2 19"
      stroke="#FCFCFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}
