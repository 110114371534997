import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";


export default function MenuLink({ uri, children }) {
  const router = useRouter();

  if (
    (uri != "/" && (router.asPath.includes(uri + "/") || router.asPath == uri)) ||
    (uri == "/" && router.pathname == "/")
  ) {
    return (
      <div className="transition hover:opacity-100 font-bold opacity-100">
        {children}
      </div>
    );
  }

  return (
    <div className="transition opacity-50 hover:opacity-100 cursor-pointer">
      <Link href={uri}>{children}</Link>
    </div>
  );
}
