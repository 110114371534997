import React from "react";
import { motion } from "framer-motion";

export default function CloseIcon({ className = "" }) {
  return (
    <motion.svg
      initial={{ rotateZ: 120 }}
      animate={{ rotateZ: 0 }}
      exit={{ rotateZ: 120 }}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      className={className}
    >
      <mask
        id="mask0_725_13628"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="20"
      >
        <path d="M22 0H0V20H22V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_725_13628)">
        <path
          d="M0.91626 0.833984L21.0829 19.1673"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0829 0.833984L0.91626 19.1673"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </motion.svg>
  );
}
