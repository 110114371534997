import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Project from "./Project";
import Container from "./Container2";
import { useRouter } from "next/router";

function ImageViewerContent({ projectData, onCloseCallback }) {
  const mouseContainerRef = React.useRef(null);
  const [isImageViewerOpen, setImageViewerOpen] = React.useState(false);
  const nextURL = "/project/" + projectData?.slug;
  const nextTitle = "My new page title";
  const nextState = { additionalInformation: "Updated the URL with JS" };
  let CarouselImages = [];
  const router = useRouter();

  window.history.pushState(nextState, nextTitle, nextURL);
  window.history.replaceState(nextState, nextTitle, nextURL);

  const handleCloseCallback = (e) => {
    window.history.pushState(nextState, nextTitle, router.asPath);
    window.history.replaceState(nextState, nextTitle, router.asPath);

    onCloseCallback(e);
  };

  React.useEffect(() => {
    if (projectData) {
      document.body.style.overflow = "hidden";
      if (document.getElementsByTagName("main")[0]) {
        document.getElementsByTagName("main")[0].classList.add("lg:pr-[15px]");
      }
      if (document.querySelectorAll<HTMLElement>(".header")[0]) {
        document
          .querySelectorAll<HTMLElement>(".header")[0]
          .classList.add("lg:pr-[15px]");
      }
    }
    return () => {
      document.body.style.overflow = "unset";
      if (document.getElementsByTagName("main")[0]) {
        document
          .getElementsByTagName("main")[0]
          .classList.remove("lg:pr-[15px]");
      }
      if (document.querySelectorAll<HTMLElement>(".header")[0]) {
        document
          .querySelectorAll<HTMLElement>(".header")[0]
          .classList.remove("lg:pr-[15px]");
      }
    };
  }, [isImageViewerOpen]);

  // Trigger
  React.useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();

        // 👇️ your logic here
        handleCloseCallback(null);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  if (projectData) {
    return (
      <motion.section
        ref={mouseContainerRef}
        className="modal fixed top-0 z-50 h-full w-full pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        transition={{
          type: "ease",
        }}
        key={projectData}
      >
        <div className="image-viewer bg-[#121212] bg-opacity-[0.97] backdrop-blur pointer-events-auto">
          <Container>
            <motion.div
              className="h-screen py-8 portrait:md:py-16 lg:py-16"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 0, 0, 1] }}
              exit={{ opacity: [1, 0, 0, 0] }}
              transition={{
                duration: 0.3,
                type: "easeIn",
                times: [0, 0.3, 0.7, 1],
              }}
            >
              <Project
                projectData={projectData}
                onCloseCallback={handleCloseCallback}
                mouseContainerRef={mouseContainerRef}
                setImageViewerOpen={setImageViewerOpen}
                isImageViewerOpen={isImageViewerOpen}
                CarouselImages={CarouselImages}
              />
            </motion.div>
          </Container>
        </div>
      </motion.section>
    );
  }

  return null;
}

export default function ImageViewer({ projectData, onCloseCallback }) {
  return (
    <>
      <AnimatePresence>
        {projectData && (
          <ImageViewerContent
            key={projectData}
            projectData={projectData}
            onCloseCallback={onCloseCallback}
          />
        )}
      </AnimatePresence>
    </>
  );
}

// or Dynamic metadata
export async function generateMetadata({ params }) {
  return {
    title: "Carlos",
  };
}
