export const primaryMenu = [
  {
    title: "About",
    url: "/pages/about-us",
  },
  {
    title: "Contacts",
    url: "/pages/contacts",
  },
  {
    title: "Request a Quote",
    url: "/pages/request-a-quote",
  },
];