import React from "react";
import DesktopProjectContent from "./DesktopProjectContent";
import MobileProjectContent from "./MobileProjectContent";
import AnimOpenIcon from "./icons/AnimOpenIcon";
import DownloadIcon from "./icons/DownloadIcon";
import AnimLoadingIcon from "./icons/AnimLoadingIcon";
import Share from "./Share";
import { useQuery } from "react-query";
import { getPostMedia } from "../lib/api";
import CostumCarrousel from "./CostumCarrousel";
import ProjectCloseIcon from "./icons/ProjectCloseIcon";

type ProjectProps = {
  projectData: any;
  onCloseCallback: any;
  mouseContainerRef: any;
  CarouselImages: any;
  isImageViewerOpen: boolean;
  setImageViewerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Project({
  onCloseCallback = null,
  projectData,
  mouseContainerRef = null,
  CarouselImages = null,
  ...props
}: ProjectProps) {
  const query = useQuery("projectMedia_" + projectData.projectId, () => {
    return getPostMedia(projectData.projectId);
  });
  const projectInfoContainer = React.useRef(null);
  const carrouselImage = React.useRef(null);

  if (query.isSuccess) {
    CarouselImages = query.data.edges;
  }

  function handleImageViewerOpening() {
    console.log('handleImageViewerOpening')
    if (props.isImageViewerOpen) {
      props.setImageViewerOpen(false);
      return;
    }

    props.setImageViewerOpen(true);
    return;
  }

  const getVideoSlide = () => {
    if (projectData.fields.video) {
      return [
        ...CarouselImages,
        {
          type: "video",
          video: projectData.fields.video,
        },
      ];
    }

    return CarouselImages;
  };

  return (
    <div
      className="project-container h-full z-50 pointer-events-auto"
      ref={mouseContainerRef}
    >
      <div className="flex h-full w-full gap-y-8 landscape:gap-y-6 landscape:lg:gap-y-8 flex-col">
        <div className="relative min-h-[2rem]">
          <div className="flex justify-center items-center text-center absolute w-full h-full px-10">
            <h1 className="font-medium text-base md:text-lg lg:text-xl text-white">
              {projectData.title}
            </h1>
          </div>

          {onCloseCallback && (
            <div className="close-element col-span-1 lg:col-span-8 lg:row-start-1 lg:col-start-5 flex justify-end relative z-10">
              <div className="flex flex-col items-center">
                <i
                  className="cursor-pointer hover:opacity-70"
                  onClick={() => onCloseCallback(null)}
                >
                  <ProjectCloseIcon />
                </i>
                <span className="text-white text-xs opacity-50 text-center leading-tight hidden lg:landscape:block xl:block">
                  press
                  <br />
                  escape
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="overflow-hidden grow cursor-auto" ref={carrouselImage}>
          <div
            className={`justify-center flex-col basis-1/2 grow shrink-1 md:shrink-0 carousel-element col-span-1 lg:col-span-8 portrait:lg:col-span-7 ${
              onCloseCallback ? "lg:row-start-2" : ""
            } md:row-span-1 lg:col-start-5 flex items-end overflow-hidden h-full`}
          >
            {query.isSuccess ? (
              <CostumCarrousel
                {...props}
                slides={getVideoSlide()}
                mouseContainerRef={mouseContainerRef}
                args={{
                  onCloseCallback: onCloseCallback,
                  projectInfoContainerRef: projectInfoContainer,
                  carrouselImage: carrouselImage,
                }}
              />
            ) : (
              <div className="flex justify-center items-center w-full h-full invert">
                <AnimLoadingIcon />
              </div>
            )}
          </div>
        </div>

        <div>
          <div
            className={`${
              props.isImageViewerOpen ? "" : "basis-[7rem]"
            } data-element col-span-1 lg:col-span-4 portrait:lg:col-span-5 xl:col-span-3 overflow-visible ${
              onCloseCallback ? "lg:row-start-2" : ""
            } lg:row-span-1 lg:col-start-1 flex items-start overflow-hidden h-full content-end text-white flex-col gap-y-8 gap-6 justify-end order-last lg:order-none`}
          >
            <div className="flex flex-row gap-8 items-center justify-center w-full">
              <div
                className="flex gap-8 cursor-auto"
                ref={projectInfoContainer}
              >
                <DesktopProjectContent projectData={projectData} />

                <div className="flex gap-8">
                  <div className="mr-auto md:hidden flex gap-6">
                    <button
                      className="transition hover:opacity-70"
                      onClick={handleImageViewerOpening}
                    >
                      <AnimOpenIcon isOpen={props.isImageViewerOpen} />
                    </button>
                    {projectData.fields.download?.mediaItemUrl && (
                      <a
                        className="transition hover:opacity-70"
                        href={projectData.fields.download?.mediaItemUrl}
                        target="_blank"
                        download
                      >
                        <DownloadIcon />
                      </a>
                    )}
                  </div>
                  <Share />

                  {projectData.fields.download?.mediaItemUrl && (
                    <a
                      className="transition hover:opacity-70 hidden md:block"
                      href={projectData.fields.download?.mediaItemUrl}
                      target="_blank"
                      download
                    >
                      <DownloadIcon />
                    </a>
                  )}
                </div>
              </div>
            </div>

            <MobileProjectContent
              isOpen={props.isImageViewerOpen}
              projectData={projectData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
