import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import TopArrow from "./TopArrow";
import PlusIcon from "./PlusIcon";

export default function AnimOpenIcon({ isOpen }) {
  return (
    <div className="h-6 w-6 relative">
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            className="absolute top-0 left-0"
            initial={{ scale: 0 }}
            animate={{
              scale: 1,
            }}
            exit={{ scale: 0 }}
            key="out"
          >
            <TopArrow />
          </motion.div>
        ) : (
          <motion.div
            className="absolute top-0 left-0"
            initial={{ scale: 0 }}
            animate={{
              scale: 1,
            }}
            exit={{ scale: 0 }}
            key="in"
          >
            <PlusIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
