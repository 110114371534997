import Link from "next/link";
import React from "react";

export default function DesktopProjectContent({ projectData }) {
  const paragraphClass = "inline";
  const itemClass = "gap-3 text-center inline whitespace-break-spaces";
  const itemHeadingClass = "font-medium lowercase inline mr-4";

  function Item({
    title,
    param,
    coauthor,
  }: {
    title: string;
    param: string;
    coauthor?: boolean;
  }) {
    const hasCoauthor = coauthor && projectData.fields[param + "Coauthor"];
    const linkClass =
      "transition-all hover:opacity-70 hover:underline underline-offset-4 decoration-neutral-700";

    if (projectData.fields?.[param]) {
      const url = projectData.fields[param + "Url"];

      const FieldContent = () => {
        return <p className={paragraphClass}>{projectData.fields[param]}</p>;
      };

      return (
        <div className={itemClass}>
          <h2 className={itemHeadingClass}>{title}</h2>
          {hasCoauthor ? (
            url ? (
              <>
                <FieldContent />
                <span>{" + "}</span>
                <Link
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={linkClass}
                >
                  <span>{projectData.fields[param + "Coauthor"]}</span>
                </Link>
              </>
            ) : (
              <>
                <FieldContent />
                <span>{" + "}</span>
                <span>{projectData.fields[param + "Coauthor"]}</span>
              </>
            )
          ) : url ? (
            <Link
              href={url}
              target="_blank"
              rel="noreferrer"
              className={linkClass}
            >
              <FieldContent />
            </Link>
          ) : (
            <FieldContent />
          )}
        </div>
      );
    }

    return <></>;
  }

  return (
    <div className="font-regular text-base hidden md:flex whitespace-nowrap flex-row gap-y-2 flex-wrap gap-x-8 justify-center items-center">
      <Item title="Architecture" param="architecture" coauthor={true} />
      <Item title="Location" param="location" />
      <Item title="Date" param="date" />
      <Item title="Project Team" param="projectTeam" />
      <Item title="Client" param="client" />
      <Item title="Construction" param="construction" />
      <Item title="Photography" param="photography" coauthor={true} />
      <Item title="State" param="state" />
      <Item title="3d" param="field3d" coauthor={true} />
    </div>
  );
}
