import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Container from "./Container2";
import { useQuery } from "react-query";
import HomeImage from "./HomeImage";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CloseIcon from "./icons/CloseIcon";
import useMouse from "@react-hook/mouse-position";
import CloseSearchFloatingIcon from "./icons/CloseSearchFloatingIcon";
import { useRouter } from "next/router";

const getSearch = async (searchString) => {
  const res = await fetch("/api/search/" + searchString);
  return res.json();
};

function SearchResultsContainer({
  searchQuery,
  setOpenedImage,
  handleSearchSwitch,
}) {
  const searchString = searchQuery;
  const query = useQuery(
    searchString,
    () => {
      return getSearch(searchString);
    },
    { staleTime: Infinity }
  );

  if (query?.isLoading) {
    return (
      <motion.div
        key={1}
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.5, type: "easeIn" }}
        className="w-full p-8 lg:p-16 bg-white z-40 relative shadow-2xl pointer-events-auto"
      >
        <SearchResults
          query={query}
          state={"loading"}
          setOpenedImage={setOpenedImage}
          handleSearchSwitch={handleSearchSwitch}
        />
      </motion.div>
    );
  }

  if (query?.isSuccess && query?.data?.search?.edges?.length < 1) {
    return (
      <>
        <motion.div
          key={2}
          initial={{ y: "100%" }}
          animate={{ y: "0%" }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.5, type: "easeIn" }}
          className="w-full p-8 lg:p-16 bg-white z-40 relative shadow-2xl pointer-events-auto"
        >
          <SearchResults
            query={query}
            state={"empty"}
            searchString={searchString}
            setOpenedImage={setOpenedImage}
            handleSearchSwitch={handleSearchSwitch}
          />
        </motion.div>
      </>
    );
  }

  return (
    <motion.div
      key={3}
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      exit={{ y: "100%" }}
      transition={{ duration: 0.5, type: "easeIn" }}
      className="w-full p-8 lg:p-16 bg-white z-40 relative shadow-2xl pointer-events-auto"
    >
      <SearchResults
        query={query}
        state={"ready"}
        searchString={searchString}
        setOpenedImage={setOpenedImage}
        handleSearchSwitch={handleSearchSwitch}
      />
    </motion.div>
  );
}

function SearchResults({
  state,
  query,
  searchString = "",
  setOpenedImage = null,
  handleSearchSwitch,
}) {
  let handleSearchResultItemClick = (input) => {
    setOpenedImage(input);
  };

  if (state == "loading") {
    return (
      <>
        <h3 className="font-medium text-base md:text-lg lg:text-2xl lowercase animate-pulse">
          Loading...
        </h3>
        <div className="w-full bg-gray-200"></div>
      </>
    );
  }

  if (query.isSuccess && query.data?.search?.edges?.length != 0) {
    let data = query.data.search.edges;

    return (
      <>
        <h3 className="font-medium text-lg lg:text-2xl lowercase mb-8">
          Results for <span className="opacity-30">{searchString}</span>
        </h3>
        <div className="w-full">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 0: 1, 768: 2, 1024: 3 }}
          >
            <Masonry gutter={"1rem"}>
              {data.map((item, i) => {
                return (
                  <HomeImage
                    key={i}
                    projectData={data[i].node}
                    visible={true}
                    projectThumbnail={data[i].node.featuredImage?.node}
                    onClickCallback={handleSearchResultItemClick}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </>
    );
  }

  return (
    <>
      <h3 className="font-medium text-2xl lowercase mb-4">
        Nothing found for <span className="opacity-30">{searchString}</span>
      </h3>
      <div className="w-full text-lg">
        Please try again with other keywords.
      </div>
    </>
  );
}

function SearchModalLogic({
  handleSearchSwitch,
  isSearchOpen,
  setSearchQuery,
  searchQuery,
  setOpenedImage,
  setSearchOpen,
  ...props
}) {
  const [searchSlug, setSearchSlug] = useState();
  // const [queryClient] = React.useState(() => new QueryClient());
  const [cursorVariant, setCursorVariant] = useState("default");
  const [closing, setClosing] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (isSearchOpen && router.pathname != "/project/[slug]") {
      document.body.style.overflow = "hidden";
      if (document.getElementsByTagName("main")[0]) {
        document.getElementsByTagName("main")[0].classList.add("lg:pr-[15px]");
      }
      if (document.querySelectorAll<HTMLElement>(".header")[0]) {
        document
          .querySelectorAll<HTMLElement>(".header")[0]
          .classList.add("lg:pr-[15px]");
      }
    }
    return () => {
      document.body.style.overflow = "unset";
      if (document.getElementsByTagName("main")[0]) {
        document
          .getElementsByTagName("main")[0]
          .classList.remove("lg:pr-[15px]");
      }
      if (document.querySelectorAll<HTMLElement>(".header")[0]) {
        document
          .querySelectorAll<HTMLElement>(".header")[0]
          .classList.remove("lg:pr-[15px]");
      }
    };
  }, [isSearchOpen]);

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(searchSlug);
  };

  const handleInputChange = (event) => {
    setSearchSlug(event.target.value);
  };

  let mouseXPosition = 0;
  let mouseYPosition = 0;
  const mouse = useMouse(props.mouseContainerRef, {
    enterDelay: 50,
    leaveDelay: 50,
    fps: 75,
  });

  if (mouse.clientX !== null) mouseXPosition = mouse.clientX;
  if (mouse.clientY !== null) mouseYPosition = mouse.clientY;

  const variants = {
    default: {
      opacity: 0,
      height: 5,
      width: 5,
      x: mouseXPosition - 2.5,
      y: mouseYPosition - 2.5,
    },
    close: {
      opacity: 1,
      height: 80,
      width: 80,
      x: mouseXPosition - 40,
      y: mouseYPosition - 40,
    },
    none: {
      opacity: 0,
      height: 80,
      width: 80,
      x: mouseXPosition - 40,
      y: mouseYPosition - 40,
    },
  };

  const spring = {
    type: "linear",
    duration: 0.025,
  };

  function onZoneMouseEnter(event) {
    setCursorVariant("close");
  }

  function onZoneMouseLeave(event) {
    setCursorVariant("default");
  }

  function handleCloseModalOnZone() {
    setCursorVariant("none");
    handleSearchSwitch();
  }

  // Trigger
  React.useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setClosing(true);
        // 👇️ your logic here

        setCursorVariant("none");
        setSearchOpen(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div className="fixed bottom-0 w-full z-50 select-none text-[#121212]">
      <div
        className="mouse-container pointer-events-none top-0 left-0 fixed z-50 w-full h-full select-none"
        ref={props.mouseContainerRef}
      >
        {isSearchOpen && cursorVariant == "close" && (
          <motion.div
            key={"mouse"}
            className="hidden md:flex mouse z-50 absolute pointer-events-none text-white justify-center items-center rounded-full text-xl font-bold bg-white bg-opacity-30"
            variants={variants}
            animate={cursorVariant}
            transition={spring}
          >
            <div>
              <CloseSearchFloatingIcon />
            </div>
          </motion.div>
        )}
      </div>
        <AnimatePresence>
          {isSearchOpen && (
            <motion.div // Shadow
              key={1}
              initial={{ opacity: 0, zIndex: 20 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, type: "easeOut" }}
              className="absolute bg-gradient-to-t from-[#000000dd] to-[#00000044] z-20 h-screen w-full bottom-0 left-0 pointer-events-all cursor-none"
              onClick={handleCloseModalOnZone}
              onMouseEnter={onZoneMouseEnter}
              onMouseLeave={onZoneMouseLeave}
            />
          )}
          {isSearchOpen && searchQuery && (
            <motion.div // Results
              key={2}
              initial={{ y: "120%", opacity: 0.5, zIndex: 40 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "120%" }}
              transition={{ duration: 0.35, type: "easeIn" }}
              className="absolute w-full bottom-0 z-40 px-6 md:px-16 h-100vh pb-32 lg:pb-40 overflow-y-auto h-screen pt-12 lg:pt-64 flex flex-wrap items-end pointer-events-none scrollbar-hide"
              onClick={handleSearchSwitch}
            >
              <SearchResultsContainer
                setOpenedImage={setOpenedImage}
                searchQuery={searchQuery}
                handleSearchSwitch={handleSearchSwitch}
              />
            </motion.div>
          )}
          {isSearchOpen && (
            <motion.div // Form
              className="relative pointer-events-auto z-50 w-full bottom-0 shadow-2xl border border-t-gray-100"
              key={3}
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200 }}
              transition={{ duration: 0.35, type: "easeIn" }}
            >
              <div className="bg-white h-32 lg:h-40 2xl:h-48 flex items-center w-full">
                <div className="basis-full">
                  <Container>
                    <form
                      className="border-b 2xl:border-b-2 border-[#121212] py-4 text-lg lg:text-xl 3xl:text-3xl flex gap-4 justify-between"
                      onSubmit={handleSearch}
                    >
                      <button
                        className="font-medium hover:opacity-50 transition"
                        type="submit"
                      >
                        Search
                      </button>
                      <input
                        type="text"
                        placeholder="What are you looking for?"
                        className="opacity-20 font-medium w-full focus:outline-none"
                        onChange={handleInputChange}
                      />
                      <button
                        className="font-medium hover:opacity-50 transition"
                        type="submit"
                        onClick={handleSearchSwitch}
                      >
                        <CloseIcon className="h-4 w-4 2xl:h-6 2xl:w-6" />
                      </button>
                    </form>
                  </Container>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
    </div>
  );
}

export default function SeachModal(props) {
  const mouseContainerRef = React.useRef(null);

  return (
    <div ref={mouseContainerRef}>
      <SearchModalLogic mouseContainerRef={mouseContainerRef} {...props} />
    </div>
  );
}
