import React from "react";
import { motion } from "framer-motion";

export default function MenuIcon({ className = "" }) {
  return (
    <motion.svg
      initial={{ rotateZ: -120 }}
      animate={{ rotateZ: 0 }}
      exit={{ rotateZ: -120 }}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      className={className}
    >
      <path
        d="M1.08337 4.77734H24.9167"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08337 12.8965H24.9167"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08337 21.2227H24.9167"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
