import React from "react";
import { motion } from "framer-motion";

export default function AnimLoadingIcon() {
  return (
    <motion.svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.rect
        x="23.625"
        width="18.375"
        height="18.375"
        fill="#ACACAC"
        animate={{
          translateX: [0, -24, -24, -24, 0],
          translateY: [0, 0, 0, 24, 0],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.25, 0.75, 1, 1.001],
          repeat: Infinity,
          repeatType: "loop",
        }}
      />
      <motion.rect
        y="23.625"
        width="18.375"
        height="18.375"
        fill="#ACACAC"
        animate={{
          translateX: [0, 0, 0, 24, 24, 0],
          translateY: [0, 0, 0, 0, 0],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1, 1.001],
          repeat: Infinity,
          repeatType: "loop",
        }}
      />
      <motion.rect
        x="23.625"
        y="23.625"
        width="18.375"
        height="18.375"
        fill="#ACACAC"
        animate={{
          translateY: [0, 0, -24, -24, -24, 0],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1, 1.001],
          repeat: Infinity,
          repeatType: "loop"
        }}
      />
      <motion.rect
        width="18.375"
        height="18.375"
        fill="#121212"
        animate={{
          translateX: [0, 24, 24, 0, 0],
          translateY: [0, 0, 24, 24, 0],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1],
          repeat: Infinity,
          repeatType: "loop",
          repeatDelay: 0,
        }}
      />
    </motion.svg>
  );
}
