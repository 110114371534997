import Head from "next/head";

export default function MetaData3({ seoData }: any) {

  console.log("MetaData3");
  if(!seoData) return <></>;

  console.log("MetaData3")
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <meta name="title" content={seoData.title} />
      <meta name="description" content={seoData.description} key="description" />

      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />

      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="canonical" href={seoData.canonical} />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />

      <meta property="og:type" content={seoData.type} />
      <meta property="og:url" content={seoData.canonical} />
      <meta property="og:title" content={seoData.title} />
      <meta property="og:image" content={seoData.image} />
      <meta
          property="og:description"
          content={seoData.description}
          key="og:description"
        />

      <meta name="title" content={seoData.title} />
      <meta
        name="description"
        content={seoData.description}
        key="description"
      />

      <title>{seoData.title}</title>
    </Head>
  );
}
