import useMouse from "@react-hook/mouse-position";
import Image from "next/image";
import React, { useState } from "react";
import { motion } from "framer-motion";
import ArrowNext from "./icons/ArrowNext";
import ArrowPrev from "./icons/ArrowPrev";
import CloseIcon from "./icons/CloseIcon";

interface MouseGestures {
  MouseElement: JSX.Element;
  swiperLeft: (event: any) => void;
  swiperRight: (event: any) => void;
  swiperLeave: (event: any) => void;
}

/**
 * useMouseGestures hook
 */
function useMouseGestures(
  mouseContainerRef = null,
  args = {
    projectInfoContainerRef: null,
    carrouselImage: null,
    onCloseCallback: Function,
  }
): MouseGestures {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("close");

  const mouseOnProjectInfo = useMouse(args.projectInfoContainerRef);
  const mouseOnCarrouselImage = useMouse(args.carrouselImage);
  const mouse = useMouse(mouseContainerRef, {
    enterDelay: 50,
    leaveDelay: 50,
    fps: 75,
  });

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  if (mouse.x !== null || mouse.y !== null) {
    mouseXPosition = mouse.x;
    mouseYPosition = mouse.y;
  } else if (cursorVariant !== "default") {
    setCursorVariant("default");
  }

  React.useEffect(() => {
    if (mouse.x == null && mouse.y == null) {
      setCursorVariant("default");
    }

    if (cursorText !== "" && cursorVariant !== "project")
      setCursorVariant("project");

    if (
      mouseOnCarrouselImage.isOver &&
      cursorVariant !== "default" &&
      cursorText == ""
    ) {
      setCursorVariant("default");
    }

    if (
      mouseOnProjectInfo.isOver &&
      cursorVariant !== "projectInfo" &&
      cursorText == ""
    ) {
      setCursorVariant("projectInfo");
    }
  }, [
    cursorText,
    cursorVariant,
    mouseOnProjectInfo.isOver,
    mouseOnCarrouselImage.isOver,
  ]);

  if ((mouse.isTouch || mouse.isDown) && cursorVariant == "close") {
    args.onCloseCallback(null);
  }

  const variants = {
    default: {
      opacity: 0,
      height: 0,
      width: 0,
      x: mouseXPosition,
      y: mouseYPosition,
    },
    projectInfo: {
      opacity: 0,
      height: 0,
      width: 0,
      x: mouseXPosition,
      y: mouseYPosition,
    },
    project: {
      opacity: 1,
      height: 100,
      width: 100,
      x: mouseXPosition - 50,
      y: mouseYPosition - 50,
    },
  };

  const spring = {
    // type: "spring",
    // stiffness: 4000,
    // damping: 80,
    duration: 0.04,
  };

  function swiperLeft(event) {
    setCursorText("prev");
    cursorVariant !== "project" && setCursorVariant("project");
  }

  function swiperRight(event) {
    setCursorText("next");
    cursorVariant !== "project" && setCursorVariant("project");
  }

  function swiperLeave(event) {
    setCursorText("");
    cursorVariant !== "default" && setCursorVariant("default");
  }

  let MouseElement = (
    <div className="mouse-container pointer-events-none top-0 left-0 fixed z-50 w-full h-full select-none">
      <motion.div
        className="flex mouse z-50 absolute pointer-events-none justify-center items-center rounded-full text-xl font-bold bg-opacity-70"
        variants={variants}
        animate={cursorVariant}
        transition={spring}
      >
        <div>
          {cursorText == "next" &&
            cursorVariant !== "close" &&
            mouse.isTouch !== true && <ArrowNext className={"w-10 h-10"} />}
          {cursorText == "prev" &&
            cursorVariant !== "close" &&
            mouse.isTouch !== true && (
              <ArrowPrev className={"w-10 h-10"} fill={"#fff"} />
            )}
          {cursorVariant == "close" && mouse.isTouch !== true && (
            <CloseIcon className={"w-6 h-6 text-[#121212]"} />
          )}
        </div>
      </motion.div>
    </div>
  );

  return { MouseElement, swiperLeft, swiperRight, swiperLeave };
}

/**
 * useSwipeAnim hook
 */
function useSwipeAnim(handleSetPrevSlide, handleSetNextSlide) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 25;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isRightSwipe = distance > minSwipeDistance;
    const isLeftSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) handleSetPrevSlide();
    if (isRightSwipe) handleSetNextSlide();
  };

  const onDragStart = (e) => {
    e.preventDefault();
  };

  return { onTouchStart, onTouchMove, onTouchEnd, onDragStart };
}

function Slide({ image, index, isIndex }) {
  if (!image) return null;

  if (image.type == "video") {
    const ytUrl = `https://www.youtube-nocookie.com/embed/${image.video}?modestbranding=1&showinfo=0&autohide=1&rel=0&controls=1`;

    return (
      <div
        key={index}
        className={`${
          isIndex == index ? "opacity-100 z-20" : "opacity-0 z-0"
        } absolute h-full w-full transition flex items-center justify-center aspect-video`}
      >
        <iframe
          src={ytUrl}
          title="YouTube video player"
          className="z-10 transition aspect-video basis-full max-h-full max-w-full"
        />
      </div>
    );
  }

  return (
    <div
      key={index}
      className={`${
        isIndex == index ? "opacity-100 z-20" : "opacity-0 z-0"
      } absolute h-full w-full transition flex items-center justify-center`}
    >
      <Image
        quality={95}
        height={image.node.mediaDetails.height}
        width={image.node.mediaDetails.width}
        alt={"Project Image"}
        src={image.node.mediaItemUrl}
        className="object-contain object-center drag max-w-full max-h-full basis-0"
      />
    </div>
  );
}

/**
 * CostumCarrousel
 */
export default function CostumCarrousel({ slides, mouseContainerRef, args }) {
  const [isIndex, setIndex] = useState(0);
  const imagesQty = slides.length;
  const { MouseElement, swiperLeft, swiperRight, swiperLeave } =
    useMouseGestures(mouseContainerRef, args);

  function hasPrev() {
    return isIndex !== 0;
  }

  function hasNext() {
    return isIndex < imagesQty - 1;
  }

  const handleSetNextSlide = (e) => {
    console.log(isIndex);
    let newIndex = isIndex + 1;
    if (isIndex >= imagesQty - 1) return;
    setIndex(isIndex + 1);

    console.log(isIndex);

    if (newIndex >= imagesQty - 1) swiperLeave(e);
  };

  const handleSetPrevSlide = (e) => {
    let newIndex = isIndex - 1;
    if (isIndex == 0) return;
    setIndex(isIndex - 1);

    if (newIndex <= 0) swiperLeave(e);
  };

  const handleSetSpecificSlide = (i) => {
    if (isIndex == i) return;
    setIndex(i);
  };

  React.useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "ArrowRight") {
        e.preventDefault();
        handleSetNextSlide(e);
      }

      if (e.key === "ArrowLeft") {
        e.preventDefault();
        handleSetPrevSlide(e);
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isIndex]);

  const swipe = useSwipeAnim(handleSetPrevSlide, handleSetNextSlide);

  return (
    <>
      {MouseElement}
      <div className="relative h-full w-full">
        <div className="slides-container z-0 h-full w-full relative" {...swipe}>
          {slides.map((image, i) => {
            return <Slide key={i} index={i} image={image} isIndex={isIndex} />;
          })}
        </div>

        {hasPrev() && (
          <div className="z-10 w-1/6 lg:w-[45%] absolute left-0 top-0 md:block hidden h-full pointer-events-none">
            <div
              onClick={handleSetPrevSlide}
              className="w-full cursor-none pointer-events-auto h-3/4"
              onMouseEnter={swiperLeft}
              onMouseLeave={swiperLeave}
            />
          </div>
        )}

        {hasNext() && (
          <div className="z-10 w-1/6 lg:w-[45%] absolute right-0 top-0 md:block hidden h-full pointer-events-none">
            <div
              onClick={handleSetNextSlide}
              className="w-full cursor-none pointer-events-auto h-3/4"
              onMouseEnter={swiperRight}
              onMouseLeave={swiperLeave}
            />
          </div>
        )}
      </div>
      <div className="pagination mt-4 mx-auto py-4 flex gap-2 justify-center w-full transition-transform">
        {(hasNext() || hasPrev()) &&
          slides.map((image, i) => {
            return (
              <div
                key={i}
                className={`transition h-[6px] w-[6px] rounded-full bg-white cursor-pointer hover:opacity-25 ${
                  i == isIndex ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => {
                  handleSetSpecificSlide(i);
                }}
              />
            );
          })}
      </div>
    </>
  );
}
